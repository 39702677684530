@import "../colors";

.population-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  background-color: $b-grey;

  .population-item:first-child {
    padding-right: 10px;
  }

  .population-item:last-child {
    padding-left: 10px;
  }
}

.country {
  .population-items {
    grid-template-columns: 1fr;
    grid-gap: 0;

    .population-item {
      padding: 0;
    }
  }
}

.population-item {
  background-color: $navy;

  &__block {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-row {
    margin-bottom: 10px;


    &__label {
      margin-right: 15px;
      font-weight: 700;
    }
  }

  .bar-indicator-row__desc {
    width: 65px;
  }
}

.population-item.global {
  .population-item-row__value {
    margin-right: 15px;
  }

  .population-item-row__value.breakdown {
    margin-right: 0;
  }
}

.population-item {
  .population-item-row {
    display: flex;
    justify-content: space-between;
  }

  .breakdown:after {
    font-family: FontAwesome;
    content: "\f107";
    padding-left: 0.3em;
  }
}

.breakdown-label {
  white-space: nowrap;
  font-weight: 700;
  margin-bottom: 10px;
}

.forcibly-displaced-tooltip.bar-tooltip {
  .extra-data-wrap__inner:before {
    left: -5px;
    bottom: 6px;
  }
}

.breakdown-list, .forcibly-displaced-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  min-width: 160px;

  &__item, .bar-tooltip__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 0;

    span {
      font-size: 12px;
      font-weight: 400;
      margin: 0 10px 0 5px;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      margin: 0 10px 0 5px;
    }

    div:first-child {
      font-weight: 600;
    }
  }

  &__item:first-child, .bar-tooltip__item:first-child {
    margin-top: 0;
  }

  &__item-container {
    position: relative;
    margin-top: 8px;
  }

  &__item-container:first-child {
    margin-top: 0;
  }

  .total-idps {
    margin-bottom: 10px;
  }


  &__item:last-child {
    margin-bottom: 0;
  }

  .extra-data-wrap {
    display: flex;
    flex-direction: column;
    position: relative;

    &__item {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 15px;
      margin-top: 5px;
      font-size: 14px;

      span {
        font-size: 12px;
        font-weight: 400;
        margin: 0 10px 0 5px;
      }

      div:first-child {
        margin-right: 10px;
      }

      div:before {
        content: '';
        position: absolute;
        left: -11px;
        height: 1px;
        width: 6px;
        background: #adadad;
        bottom: 6px;
      }
    }
  }

  .extra-data-wrap__inner:before {
    content: '';
    position: absolute;
    height: 30px;
    width: 1px;
    background: #adadad;
    left: 3px;
    bottom: 6px;
  }

  .extra-data-wrap__inner.short:before{
    height: 10px;
  }
}

.forcibly-displaced-tooltip.bar-tooltip {
  .extra-data-wrap {
    display: flex;
    flex-direction: column;
    margin-left: 38px;

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 7px;
      font-size: 14px;

      div:first-child {
        margin-right: 5px;
      }
    }
  }

  .extra-data-wrap:before {
    left: 47px;
    bottom: 14px;

    &__item {
      div:before {
        left: -2px;
        height: 1px;
        width: 6px;
        background: #adadad;
        bottom: 6px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .country {
    .population-items {
      grid-template-columns: 1fr 1fr;
      grid-gap: 1px;

      .population-item:first-child {
        padding-right: 10px;
      }

      .population-item:last-child {
        padding-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .country {
    .population-items {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    .population-item:first-child {
      padding-right: 0;
    }

    .population-item:last-child {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
  }

  .population-items {
    grid-template-columns: 1fr;
    grid-gap: 0;

    .population-item:first-child {
      padding-right: 0;
    }

    .population-item:last-child {
      padding-left: 0;
      padding-top: 0;
    }
  }
}
